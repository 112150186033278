import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1068.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN}
          stroke="none"
          strokeWidth="1"
          fillRule="evenodd"
          transform="translate(0.000000,1068.000000) scale(0.100000,-0.100000)"
        >
          <path d="M0 10655 l0 -25 5400 0 5400 0 0 25 0 25 -5400 0 -5400 0 0 -25z" />
          <path
            d="M2595 6068 c-3 -7 -4 -348 -3 -758 3 -720 4 -745 22 -748 10 -2 22 2
27 10 10 15 12 1467 3 1492 -7 19 -43 21 -49 4z"
          />
          <path
            d="M1564 6046 c-37 -16 -74 -72 -74 -111 0 -68 67 -135 135 -135 64 0
125 62 125 127 0 97 -96 158 -186 119z"
          />
          <path
            d="M1856 5939 c-52 -61 -18 -140 61 -141 50 0 59 4 79 43 31 60 -13 129
-81 129 -25 0 -40 -8 -59 -31z"
          />
          <path
            d="M1225 5888 c-42 -15 -72 -45 -86 -85 -9 -28 -8 -43 6 -80 21 -54 63
-83 122 -83 35 0 49 6 83 38 33 32 40 46 40 76 0 59 -27 106 -74 127 -47 21
-50 21 -91 7z"
          />
          <path
            d="M2072 5780 c-69 -42 -77 -137 -17 -197 43 -43 77 -50 134 -27 58 24
84 69 78 134 -10 93 -113 140 -195 90z"
          />
          <path
            d="M2890 5764 c-13 -34 -12 -864 1 -885 7 -11 57 -14 271 -14 232 0 271
2 328 19 110 33 173 84 204 164 33 86 8 181 -67 255 l-49 47 40 45 c92 104 78
242 -32 326 -77 59 -93 61 -403 66 l-283 5 -10 -28z m463 -173 c45 -1 87 -37
87 -74 0 -13 -11 -36 -25 -51 -25 -26 -25 -27 -152 -24 l-128 3 0 70 0 70 50
5 c47 5 70 5 168 1z m83 -373 c48 -47 27 -129 -37 -142 -17 -3 -83 -6 -146 -6
-130 0 -128 -2 -127 91 1 95 -5 91 149 87 131 -3 135 -4 161 -30z"
          />
          <path
            d="M8314 5780 c-68 -21 -101 -97 -70 -162 47 -98 209 -89 243 15 16 47
0 102 -37 128 -31 22 -95 31 -136 19z"
          />
          <path
            d="M8890 5778 c-102 -24 -175 -103 -190 -207 -5 -35 -11 -66 -15 -69 -3
-3 -27 -9 -53 -12 l-47 -5 -3 -79 c-3 -87 2 -96 54 -96 17 0 36 -6 42 -13 7
-8 12 -91 14 -223 2 -115 7 -215 11 -221 9 -14 180 -19 211 -7 14 5 16 34 16
218 0 116 5 218 10 226 6 10 32 17 82 20 l73 5 0 85 0 85 -55 3 c-91 4 -100 8
-100 45 0 54 34 78 106 75 l59 -3 28 53 c17 31 26 60 22 71 -13 41 -172 71
-265 49z"
          />
          <path
            d="M1006 5609 c-35 -28 -37 -95 -3 -127 35 -33 99 -30 126 4 67 85 -38
190 -123 123z"
          />
          <path
            d="M1515 5560 l-60 -5 0 -245 0 -245 165 0 c186 0 223 10 260 70 34 55
27 96 -32 176 -9 13 -7 22 10 44 50 63 16 170 -61 194 -38 12 -198 18 -282 11z
m216 -110 c25 -14 25 -56 -1 -70 -11 -6 -46 -10 -77 -8 -63 3 -80 19 -69 61 6
26 10 27 67 27 33 0 69 -5 80 -10z m10 -190 c12 -7 19 -21 19 -40 0 -38 -23
-50 -97 -50 -65 0 -83 11 -83 50 0 14 5 31 12 38 15 15 122 16 149 2z"
          />
          <path
            d="M4104 5500 c-115 -16 -204 -77 -254 -176 -22 -44 -25 -61 -25 -164
l0 -115 37 -56 c37 -58 94 -104 162 -133 53 -22 187 -31 261 -17 69 13 183 63
191 85 6 16 -66 110 -93 120 -8 3 -42 -4 -76 -15 -72 -24 -148 -21 -197 7 -30
18 -48 49 -35 62 4 4 104 9 223 12 l217 5 3 64 c10 217 -167 354 -414 321z
m162 -206 c27 -28 30 -44 12 -62 -17 -17 -192 -16 -206 1 -19 24 19 78 63 90
43 12 107 -2 131 -29z"
          />
          <path
            d="M5634 5501 c-188 -30 -309 -208 -274 -401 29 -158 144 -253 323 -266
47 -4 108 -1 142 5 62 11 187 68 192 87 4 13 -72 110 -93 118 -8 3 -43 -4 -77
-15 -93 -32 -212 -12 -231 37 -15 40 7 45 222 44 160 -1 207 1 213 12 14 21
10 120 -6 175 -22 73 -101 153 -177 181 -67 25 -162 34 -234 23z m153 -191
c32 -19 51 -59 38 -79 -4 -7 -46 -11 -105 -11 -87 0 -101 2 -109 19 -8 15 -6
25 11 46 38 48 110 59 165 25z"
          />
          <path
            d="M6515 5496 c-22 -7 -53 -21 -70 -30 -39 -21 -52 -20 -65 4 -10 18
-21 20 -104 20 -76 0 -95 -3 -100 -16 -3 -9 -6 -150 -6 -314 0 -286 1 -300 19
-310 28 -14 194 -13 209 2 8 8 12 64 12 185 0 133 4 182 16 211 29 73 120 84
156 19 8 -14 14 -94 17 -222 l6 -200 99 -3 c64 -2 103 1 112 9 11 9 14 50 14
198 0 166 2 189 19 218 34 55 124 53 150 -3 7 -16 11 -94 11 -212 0 -133 4
-192 12 -200 16 -16 200 -16 216 0 9 9 12 75 12 238 l0 225 -28 55 c-70 138
-251 180 -395 90 -55 -34 -69 -36 -88 -14 -39 48 -155 73 -224 50z"
          />
          <path
            d="M7769 5501 c-20 -4 -60 -20 -90 -35 l-53 -28 -21 26 c-19 24 -26 26
-106 26 -53 0 -90 -5 -97 -12 -16 -16 -17 -859 -2 -889 10 -17 22 -19 108 -19
63 0 102 4 110 12 8 8 12 56 12 149 0 76 4 140 9 143 5 3 31 -4 57 -16 69 -31
190 -30 257 2 52 24 115 90 142 146 39 83 38 234 -2 326 -22 48 -82 114 -128
140 -25 14 -137 40 -154 37 -3 -1 -22 -4 -42 -8z m46 -205 c92 -39 102 -198
16 -249 -37 -22 -105 -22 -141 -1 -83 50 -77 203 10 246 43 22 70 22 115 4z"
          />
          <path
            d="M2251 5489 c-36 -14 -50 -39 -51 -86 0 -35 5 -45 29 -62 39 -28 82
-27 115 4 67 62 -6 178 -93 144z"
          />
          <path
            d="M4604 5477 c-2 -7 34 -77 81 -155 64 -105 85 -147 80 -162 -4 -12
-32 -60 -64 -108 -104 -160 -123 -191 -116 -202 4 -6 51 -10 114 -10 127 0
133 3 184 95 22 41 39 60 52 60 13 0 30 -19 53 -60 54 -97 51 -95 168 -95 103
0 134 5 134 21 0 5 -40 72 -90 149 -49 77 -90 149 -90 161 0 11 36 81 80 155
44 75 80 142 80 150 0 11 -21 14 -112 14 -62 0 -118 -4 -125 -8 -6 -4 -23 -31
-38 -59 -29 -59 -52 -86 -66 -77 -5 3 -23 33 -41 67 -17 34 -36 65 -42 69 -6
4 -62 8 -124 8 -83 0 -114 -3 -118 -13z"
          />
          <path
            d="M8252 5478 c-19 -19 -17 -611 2 -627 9 -8 51 -11 115 -10 94 1 102 3
106 23 3 11 4 156 3 321 l-3 300 -105 3 c-73 2 -109 -1 -118 -10z"
          />
          <path
            d="M9135 5481 c-5 -9 16 -70 50 -146 7 -16 32 -79 56 -140 23 -60 62
-159 86 -219 23 -59 43 -113 43 -120 0 -6 -25 -67 -54 -136 -40 -90 -52 -128
-44 -137 7 -9 43 -13 113 -13 86 0 104 3 118 18 10 10 17 22 17 25 0 4 36 87
79 185 44 97 98 222 121 277 24 55 46 105 50 110 3 6 13 26 20 45 7 19 26 63
41 97 46 100 59 135 59 149 0 11 -25 14 -120 14 l-120 0 -16 -37 c-9 -21 -24
-56 -34 -78 -10 -22 -30 -79 -45 -127 -27 -89 -39 -108 -57 -90 -5 5 -32 76
-60 158 -28 82 -55 155 -60 162 -12 14 -233 17 -243 3z"
          />
          <path
            d="M978 5345 c-92 -62 -59 -206 51 -230 44 -9 115 25 136 66 43 83 -17
189 -108 189 -29 0 -54 -8 -79 -25z"
          />
          <path
            d="M2207 5249 c-79 -42 -89 -149 -19 -212 29 -26 115 -34 147 -13 36 23
65 75 65 114 0 63 -70 133 -133 132 -12 -1 -39 -10 -60 -21z"
          />
          <path
            d="M1095 5036 c-57 -25 -72 -101 -29 -141 40 -37 94 -34 128 6 42 49 28
107 -31 138 -27 14 -31 13 -68 -3z"
          />
          <path
            d="M2097 4939 c-26 -15 -48 -65 -41 -93 9 -39 50 -70 90 -69 41 1 62 17
79 57 31 74 -57 146 -128 105z"
          />
          <path
            d="M1275 4857 c-67 -67 -54 -162 27 -206 42 -23 48 -24 91 -12 147 42
117 251 -35 251 -43 0 -55 -5 -83 -33z"
          />
          <path
            d="M1863 4863 c-12 -2 -36 -18 -52 -36 -27 -27 -31 -38 -31 -86 1 -62
10 -79 60 -110 90 -55 196 14 187 122 -7 84 -73 128 -164 110z"
          />
          <path
            d="M1546 4734 c-46 -47 -30 -118 33 -143 54 -23 121 27 121 90 0 11 -13
34 -29 50 -39 38 -88 40 -125 3z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
